import React, { useState } from "react";
import { Modal } from 'antd';
import {VideoCameraOutlined} from "@ant-design/icons";

import "./video-call-modal.scss";


const VideoCallModal = () => {

    const [ showModal, setShowModal ] = useState( false );

    return(
        <>
            <button onClick={ () => setShowModal(true) } className="video-call-button">
                <VideoCameraOutlined />
            </button>
            <Modal
                className="jitsi-call-modal"
                visible={ showModal }
                title={null}
                footer={null}
                onCancel={ () => setShowModal(false) }
                destroyOnClose={ true }
                centered
            >
                <iframe title="jitsi call"
                        allow="camera; microphone; fullscreen; display-capture"
                        src="https://video-stream.wire-it.me/supersecretvideoroomtotestvideochat"
                />
            </Modal>
        </>
    );

};

export default VideoCallModal;