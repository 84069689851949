import React, { useState } from "react";

import AuthorizationPage from "../../pages/authorization/authorization-page";

import "./authentication.scss";


const Authentication = ({ children }) => {

  const [ authToken, setAuthToken ] = useState(false);

  return <>{ authToken ? children : <AuthorizationPage setAuthToken={ setAuthToken } /> }</>;
};

export default Authentication;
