import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import VisualCard from '../components/visual-card';
import ModalSearch from '../components/modal-search';

import companies from "../mock-data/companies";

const companyList = [
  ...companies,
  ...companies,
  ...companies,
  ...companies,
  // ...companies,
  // ...companies,
  // ...companies,
  //...companies,
  //...companies,
  //...companies,

];

function shuffle(array) {
  array.sort(() => Math.random() - 0.8);
}

const Dashboard = () => {

  shuffle(companyList);

  useEffect(() => {

    VisualCard(companyList);

  }, []);

  return (
    <div className="page dashbord-page">
      <ModalSearch companies={ companies } />
      <div id="brand-box">
        { companyList.map(({ slug, logo, description }, index) => (

          <a href={ `${ slug }` } key={ index } target="_blank" className="element exhibition-booth">
            <img src={ `${ logo }` } className="logo" alt={ `${ description }` } />
          </a>
        ))
        }
      </div>
      <div id="switch-location">
        <button id="coliseum">COLISEUM</button>
        <button id="theater">THEATER</button>
        <button id="table">TABLE</button>
        <button id="sphere">SPHERE IN</button>
      </div>
    </div>
  );
};

export default Dashboard;

/*
<Link key={ index } target="_blank" className="element exhibition-booth" to={ `${ slug }` } >
  <img src={ `${ logo }` } className="logo" alt={ `${ description }` } />
</Link>
*/