// import logoArd from "../assets/ard_png.png";
// import logoBasf from "../assets/basf_png.png";
// import logoViessmann from "../assets/Viessmann-png.png";
// import logoBertelsmann from "../assets/Bertelsmann_png.png";
// import logoFAIRNET from "../assets/FAIRNET_Logo.png";
// import logoCrowd from "../assets/desktop_logo.svg";
// import logoMdr from "../assets/mdr_png.png";
// import logoNomos from "../assets/nomos_png.png";
// import logoPorsche from "../assets/porsche.png";
// import logoMp from "../assets/mp_logo.png";
// import logoRittal from "../assets/rittal_png.png";
// import logoSiemens from "../assets/siemens_png.png";
// import logoVattenfall from "../assets/vattenfall_png.png";
// import logoEex from "../assets/eex_png.png";
// import logoVng from "../assets/vng_png.png";
// import logoZdf from "../assets/zdf_png.png";
// import logoArena from "../assets/arena_png.png";
// import logoBmc from "../assets/bmc_png.png";
// import logoFraport from "../assets/fraport_png.png";
// import logoFraunhofer from "../assets/fraunhofer_png.png";
// import logoFunke from "../assets/Funke-Mediengruppe-Logo.svg.png";
// import logoJenoptik from "../assets/Jenoptik-Logo.svg.png";
// import logoSinn from "../assets/Sinn_(Uhrenhersteller)_logo.svg.png";
// import logoUmweltamt from "../assets/umweltamt_png.png";
// import logoVerivox from "../assets/verivox_png.png";
// import logoWinora from "../assets/winora_png.png";
// import logoOneAndOne from "../assets/1_1_png.png";
// import logoBulova from "../assets/bulova_png.png";
// import logoDlg from "../assets/DLG_png.png";
// import logoKronospan from "../assets/kronospan.png";
// import logoMan from "../assets/man_png.png";
// import logoMbw from "../assets/mbw_png.png";
// import logoMinolta from "../assets/minolta_png.png";
// import logoNarva from "../assets/narva_png.png";
// import logoSamsung from "../assets/samsung_png.png";
// import logoGeze from "../assets/geze_png.png";


// const companies = [
//     {
//         logo: `${ logoArd }`,
//         slug: "ard",
//         description: "ard",
//     };

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../assets/logos', false, /\.(png|jpe?g|svg)$/));

const companies = images.map(item => {
    return {
        logo: item.default,
        slug: `https://projects.invisionapp.com/share/PR10G5OMQFB8#/screens/446617409_showroom_Company_page/`,
    }
});

export default companies;