import React from 'react';
import { useParams } from "react-router-dom";

import companies from "../mock-data/companies";

import BgFairnet from "../assets/detail-page-banner.jpg";
import RobbyPhoto from "../assets/robby_contact.png";


const Intro = () => {

    const { exhibitionSlug } = useParams();

    const company = companies.find( ({ slug }) => exhibitionSlug === slug );

    return (
        <div className="intro">
            <div className="box-img">
                {/* <img src={ `${ BgFairnet }` } alt="" /> */ }
                <div className="blur-bg-box" style={ { backgroundImage: `url(${ BgFairnet })` } } />
            </div>
            <div className="element">
                {/* <img src={ `${ company.logo }` } className="logo" /> */}
            </div>
            <div className="contact-person">
                <button onClick={
                    () => {
                        document.querySelector(".video-call-button").click();
                    }
                }>
                    <p><img src={RobbyPhoto} alt="" /></p>
                    <h4>Ihr Ansprechpartner</h4>
                    <h5>Robby Palmer</h5>
                </button>
            </div>
        </div>
    );
};

export default Intro;