import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from "antd";

import Layout, { Sidebar, Content } from '../components/layout';
import ModalSearch from '../components/modal-search';
import NavBar from './../components/navbar';

import { HomeOutlined } from '@ant-design/icons';

import IntoImage from "../assets/1-fairnet-imagemotive-uebersicht.jpg";
import ProductBanner from "../assets/product-banner.png";
import ProductImage from "../assets/p01.jpg";



const ProductPage = () => {

    return (
        <div className="page company-page products">
            <ModalSearch />
            <Layout>
                <Content>
                    <div className="intro">
                        <div className="box-img">
                            <div className="blur-bg-box" style={ { backgroundImage: `url(${ ProductBanner })` } } />
                        </div>
                    </div>
                    <div className="intro-block">
                        <div className="description">
                            <h1>Wir messen uns an Ihrem Erfolg.</h1>
                            <p>
                                Messen sind für uns der Ort wirksamer und persönlicher Kommunikation für das Zusammentreffen von Menschen und
                                gemeinsamen Austausch auf Augenhöhe. Damit Sie sich als Aussteller oder Messeveranstalter künftig vor, während und
                                nach der Messe ganz auf die Begegnung mit Ihrer Zielgruppe konzentrieren können, begleitet FAIRNET als leistungsstarker
                                Messespezialist Ihren individuellen Unternehmensauftritt: auf der Leipziger Messe ebenso wie auf allen anderen
                                renommierten deutschen und internationalen Messeplätzen.
                            </p>
                            <p>Ob Individual- oder Systemlösung, ob Gemeinschaftspräsentation oder Standbauleistungen für Messeveranstalter: Ihr Messeauftritt und Ihre Veranstaltung sind bei FAIRNET in den besten Händen.</p>
                        </div>
                        <div className="slider-wrap">
                            <Carousel  infinite={false} className="intro-slider">
                                <div>
                                    <iframe
                                        title="youtube"
                                        className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/OUYH-qX4zr8?controls=0&enablejsapi=1"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />

                                </div>
                                <div>
                                    <div>
                                        <iframe
                                            title="stand"
                                            src="https://playcanv.as/p/wDOV360K/"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="box-img">
                                        <div className="blur-bg-box" style={ { backgroundImage: `url(${ IntoImage })` } } />
                                     </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                    <div className="product-list">
                        <div className="product">
                            <iframe
                                title="product"
                                src="https://playcanv.as/p/71c75ba0/"
                            />
                            <h5>Produktpräsentation als interactives WebGL Model</h5>
                        </div>
                        <div className="product">
                            <iframe
                                title="product"
                                src="https://playcanv.as/p/YbErO8G3/"
                            />
                            <h5>Produktpräsentation als interactives WebGL Modell</h5>
                        </div>
                        <div className="product">
                            <div className="blur-bg-box" style={ { backgroundImage: `url(${ ProductImage })` } } />
                            <h5>Produktpräsentation mit Bildmaterial</h5>
                        </div>
                    </div>

                </Content>
                <Sidebar>
                    <Link className="back-home" to="/">
                        <HomeOutlined  />
                    </Link>
                    <NavBar />
                </Sidebar>
            </Layout>
        </div>
    );
};

export default ProductPage;