import React from 'react'
import { Input, AutoComplete } from 'antd';
import { Link } from "react-router-dom";
// import { UserOutlined } from '@ant-design/icons';


import "./search-form.scss";

const { Search } = Input;

// const SearchForm = ({ setSearchVal, history, routPage }) => {
const SearchForm = () => {

  return (

    <Search className="search-form"
      placeholder="input search text"
      onChange={
        ({ target: { value } }) => {
          // setSearchVal(value);
          // history.push(routPage)
          console.log(value)
        }
      }
    />
  )
};



export const Complete = ({ companies }) => {

  console.log(companies[ 0 ].slug)

  const renderItem = (title) => {
    const slug = companies[ 0 ].slug;

    return {
      value: title,
      label: (
        // <Link to="/fairnet"
        //   style={ {
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //   } }
        // >
        //   {title }
        //   <span>
        //     {/* <UserOutlined /> { count } */ }
        //   </span>
        // </Link>
        <a href={ `${ slug }` }
          target="_blank"
          style={ {
            display: 'flex',
            justifyContent: 'space-between',
          } }
        >
          { title }
        </a>
      ),
    };
  };


  const options = [
    { options: [ renderItem('ard') ] },
    { options: [ renderItem('basf') ] },
    { options: [ renderItem('Viessmann') ] },
    { options: [ renderItem('Bertelsmann') ] },
    { options: [ renderItem('FAIRNET') ] },
    { options: [ renderItem('Crowd') ] },
    { options: [ renderItem('mdr') ] },
    { options: [ renderItem('nomos') ] },
    { options: [ renderItem('porsche') ] },
    { options: [ renderItem('MP') ] },
    { options: [ renderItem('rittal') ] },
    { options: [ renderItem('siemens') ] },
    { options: [ renderItem('vattenfall') ] },
    { options: [ renderItem('eex') ] },
    { options: [ renderItem('vng') ] },
    { options: [ renderItem('zdf') ] },
    { options: [ renderItem('arena') ] },
    { options: [ renderItem('sinn') ] },
    { options: [ renderItem('verlag') ] },
    { options: [ renderItem('fraport') ] },
    { options: [ renderItem('fraunhofer') ] },
    { options: [ renderItem('funke') ] },
    { options: [ renderItem('jenoptik') ] },
    { options: [ renderItem('sinn') ] },
    { options: [ renderItem('ltamt') ] },
    { options: [ renderItem('verivox') ] },
    { options: [ renderItem('winora') ] },
  ];

  return (
    <AutoComplete
      className="complete-search"
      filterOption={ true }
      dropdownClassName="complete-search-dropdown"
      // dropdownMatchSelectWidth={500}
      // style={{
      //   width: 500,
      // }}
      options={ options }
      defaultOpen={ true }
    // open={ true }
    >
      <Input.Search size="large" placeholder="input company name" />
    </AutoComplete>
  )
};

export default SearchForm;


