import React from 'react';
import Routes from "./routes";

import "antd/dist/antd.css";
import "./scss/main.scss";



function App() {
  return (
    <>
      <header id="header"></header>
      <main id="main">
        <Routes />
      </main>
      <footer id="footer"></footer>
    </>
  );
}

export default App;
