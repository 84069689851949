import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Complete } from '../components/forms/search-form';


const ModalSearch = ({ companies }) => {
  const [ visible, setVisible ] = useState(false);
  return (
    <>
      <Button className="btn-modal-search" onClick={ () => setVisible(true) }>
        <SearchOutlined className="search-outlined" />
      </Button>

      <Modal
        className="modal-complete-seaech"
        title="Search Company"
        centered
        visible={ visible }
        onOk={ () => setVisible(false) }
        onCancel={ () => setVisible(false) }
        width={ 500 }
        footer={ null }
      >
        <Complete companies={ companies } />
      </Modal>
    </>
  );
};

export default ModalSearch;
