import React from 'react';
import { Carousel } from "antd";
import {
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons';

import "./media-slider.scss"


const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <RightOutlined />
        </button>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <LeftOutlined />
        </button>
    );
};

const MediaSlider = ({ children }) => {

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (index) => {
            const players = document.querySelectorAll(".embed-responsive-item");
            players[index].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
    };

    return(
        <Carousel {...settings}>
            {
                children.map((item, index) => {
                    return(
                        <div key={index} className="slide">
                            { item }
                        </div>
                    )
                })
            }
        </Carousel>
    );
};

export default MediaSlider;