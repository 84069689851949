import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { useMutation } from "@apollo/react-hooks";
// import { errorNotification } from "../../../error";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, notification } from "antd";
import "./login-form.scss";
import logo from "../../../assets/login-form-tittle.png";

// import { USER_LOGIN } from "../../../graphql/mutations";


const openNotificationWithIcon = type => {
  notification[ type ]({
    message: 'Authentication failed',
    description:
      'The user credentials were incorrect',
  });
};

class NormalLoginForm extends Component {
  formSubmit = ({ event, form, setAuthToken }) => {

    event.preventDefault();

    form.validateFields((err, values) => {
      if (
        (values.username === 'demo' && values.password === '123456')
      )
      {

        setAuthToken(true)

      } else
      {

        openNotificationWithIcon('error')

      }
    });
  };

  render() {
    const { form, loginLoading, loginRequest, setAuthToken } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className="form-holder">
        <div className="logo-img">
          <h1 style={ { color: '#192442' } }>eroFame 2021</h1>
          {/* <img src={ logo } alt="logo" /> */}
        </div>
        <h2>
          Sign in
        </h2>
        <Form
          className="login-form"
          onSubmit={ event => {
            this.formSubmit({ event, form, loginRequest, setAuthToken });
          } }
        >
          <Form.Item>
            { getFieldDecorator("username", {
              rules: [
                { required: true, message: "Please input your username!" }
              ]
            })(
              <Input
                prefix={ <UserOutlined className="form-icon" /> }
                placeholder="Username"
              />
            ) }
          </Form.Item>
          <Form.Item>
            { getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input.Password
                prefix={ <LockOutlined className="form-icon" /> }
                type="password"
                placeholder="Password"
              />
            ) }
          </Form.Item>
          <Form.Item>
            <Link to="/" className="login-form-link" disabled>
              Forgot password?
            </Link>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-submit"
              loading={ loginLoading }
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
        {/* <div className="divider">
                    <div /><div>OR</div><div />
                </div>
                <div className="sign-link-wrap">
                    <p>You don't have account? </p>
                    <Link to="/registration/" className="sign-link">Sign Up</Link>
                </div> */}
      </div>
    );
  }
}

const LoginFormContainer = props => {


  const loginRequest = false;
  const loading = false;

  return (
    <NormalLoginForm
      { ...props }
      loginRequest={ loginRequest }
      loginLoading={ loading }
    />
  );
};

const LoginForm = Form.create({ name: "authorization" })(LoginFormContainer);

export default LoginForm;
