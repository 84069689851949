import React from 'react';


const Layout = (props) => {

  return (
    <div className="layout">
      { props.children }
    </div>
  );
};


export const Content = (props) => {

  return (

    <div className="content-holder">
      { props.children }
    </div>
  );
};


export const Container = (props) => {

  return (
    <div className="container">
      { props.children }
    </div>
  );
};


export const Sidebar = (props) => {

  return (
    <div className="sidebar">
      { props.children }
    </div>
  );
};



export default Layout;
