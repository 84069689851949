import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import Layout, { Sidebar, Content } from '../components/layout';
import ModalSearch from '../components/modal-search';
import NavBar from './../components/navbar';


const PanoramaPage = () => {

    return (
        <div className="page company-page panorama">
            <ModalSearch />
            <Layout>
                <Content>
                    <iframe
                        className="panorama-frame"
                        title="Panorama"
                        src="https://www.thinglink.com/mediacard/1389997991418396675"
                        frameBorder="0"
                        allowFullScreen
                        scrolling="no"
                    />
                </Content>
                <Sidebar>
                    <Link className="back-home" to="/">
                        <HomeOutlined className="" />
                    </Link>
                    <NavBar />
                </Sidebar>
            </Layout>
        </div>
    );
};

export default PanoramaPage;