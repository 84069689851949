import React from "react";
import { Switch, Route } from "react-router-dom";

import Dashboard from "./pages/dashboard";
import ProductPage from "./pages/product-page";
import PanoramaPage from "./pages/panorama-page";
import CompanyPage from "./pages/company-page";

import NoPageFound from "./pages/no-page-found";


const Routes = () => {

  return (
    <Switch>
      <Route path="/" component={ Dashboard } exact />
      <Route path="/dashboard" component={ Dashboard } exact />
      <Route path="/:exhibitionSlug/panorama" component={ PanoramaPage } exact />
      <Route path="/:exhibitionSlug/product" component={ ProductPage } exact />
      <Route path="/:exhibitionSlug" component={ CompanyPage } exact />

      <Route component={ NoPageFound } />
    </Switch>
  );
};

export default Routes;
