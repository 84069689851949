import React from 'react';
import { Link, useRouteMatch, useParams } from "react-router-dom";
import {
    GlobalOutlined,
    FacebookOutlined,
    InstagramOutlined,
    CloudDownloadOutlined,
    EnvironmentOutlined,
    ShareAltOutlined,
    ShopOutlined,
    ProfileOutlined
} from '@ant-design/icons';

import { VideoCallModal } from "./video-call";
import Icon360 from "./icons/icon-360";

const NavBar = () => {

    const { exhibitionSlug } = useParams();

    //let match = useRouteMatch(`/${ exhibitionSlug }/:subPage`);

    return (
        <ul className="navbar">
            <li>
                <Link to={`/${exhibitionSlug}`}>
                    <ProfileOutlined />
                </Link>
            </li>

            <li>
                <Link to={`/${exhibitionSlug}/product`}>
                    <ShopOutlined />
                </Link>
            </li>
            <li>
                <Link to={`/${exhibitionSlug}/panorama`}>
                    <Icon360 />
                </Link>
            </li>


            <li>
                <VideoCallModal />
            </li>
            <li>
                <a href="https://www.fairnet.de/media/PDF/2020/Hygieneschutzmassnahmen_Standkonzepte_200817.pdf" target="_blank" rel="noreferrer" download="">
                    <CloudDownloadOutlined />
                </a>
            </li>
            <li>
                <a href="https://goo.gl/maps/BrwhFyYT8oxwpfVn8" target="_blank" rel="noreferrer">
                    <EnvironmentOutlined />
                </a>
            </li>
            <li>
                <button className="sub-nav-but" ><ShareAltOutlined /></button>
                <ul className="sub-nav">
                    <li>
                      <a href="https://www.instagram.com/fairnet.leipzig/" target="_blank" rel="noreferrer">
                          <InstagramOutlined />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/fairnet.leipzig/" target="_blank" rel="noreferrer">
                          <FacebookOutlined />
                      </a>
                    </li>
                    <li>
                        <a href="https://www.fairnet.de/" target="_blank" rel="noreferrer"><GlobalOutlined /></a>
                    </li>
                </ul>
            </li>

        </ul>
    )
};

export default NavBar;