import React from 'react';
import { Link } from 'react-router-dom';


import Layout, { Sidebar, Container, Content } from '../components/layout';
import ModalSearch from '../components/modal-search';
import NavBar from './../components/navbar';
import Intro from './../components/intro';
import MediaSlider from "../components/media-slider";

import { HomeOutlined } from '@ant-design/icons';


const CompanyPage = () => {

  return (
    <div className="page company-page">
      <ModalSearch />
      <Layout>
        <Content>
          <Intro />
          <Container>
            <h1>FAIRNET | Ihr 360°-Dienstleister für Kommunikation im Raum.</h1>
            <p>FAIRNET ist der Spezialist, der Ihre Marke und Produkte auf Messen oder auf Kongressen wirkungsvoll inszeniert. Unser Anspruch sind einzigartige Konzepte, die genügend Raum für Ihren Erfolg schaffen. Wir sind dabei kreativ in der Idee und innovativ bei der Umsetzung.</p>
            <p>Vom ersten Brainstorming bis zur Projektübergabe erhalten Sie alles aus einer Hand. Unser Ziel ist es, Ihnen so viel Arbeit wie möglich abzunehmen. Deshalb steht als Ansprechpartner immer ein Projektleiter zur Seite, der alle Abläufe professionell steuert und Sie umfassend berät.</p>
            <p>Wir machen Individualität sichtbar und setzen Standards bei Kundenbetreuung, Qualität und Flexibilität. Von Anfang bis Erfolg.</p>
            <MediaSlider>
              <div className="embed-responsive">
                <iframe
                    title="youtube"
                    className="embed-responsive-item"
                    width="560" height="315"
                    src="https://www.youtube.com/embed/BI40DfQE6p8?&enablejsapi=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
              </div>
              <div className="embed-responsive">
                <iframe
                    title="youtube"
                    className="embed-responsive-item"
                    width="560" height="315"
                    src="https://www.youtube.com/embed/em_LLlKubxI?&enablejsapi=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
              </div>
              <div className="embed-responsive">
                <iframe
                    title="youtube"
                    className="embed-responsive-item"
                    width="560" height="315"
                    src="https://www.youtube.com/embed/Owuxd7Ycp_A?&enablejsapi=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
              </div>
            </MediaSlider>
          </Container>
        </Content>
        <Sidebar>
          <Link className="back-home" to="/">
            <HomeOutlined className="" />
          </Link>
          <NavBar />
        </Sidebar>
      </Layout>
    </div>
  );
};

export default CompanyPage;